import type { Dispatch } from 'redux';

import { notify } from 'Components/common/notify';

import { ENDPOINTS } from 'Utils/apiEndpoints';
import { sendCoralogixLog } from 'Utils/coralogix/log';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentCurrency } from 'Utils/stateUtils';
import { getApiCDNBaseUrl, getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	receiveProduct,
	receiveProductFeatureFlags,
	receiveProducts,
	receiveTranslatedProductContent,
	requestProduct,
	requestProducts,
	requestTranslatedProductContent,
} from 'Actions/product';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { LOG_CATEGORIES, LOG_LEVELS } from 'Constants/constants';

export const fetchProductCards =
	({ productIds, lang = 'en', req }: any) =>
	(dispatch: any, getState: any) => {
		if (!productIds || !productIds.length) return Promise.resolve();
		dispatch(requestProducts({ productIds }));
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const unavailableTours = '&include-unavailable=true';
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v6/tour-groups/?ids[]=${productIds.join(
			',',
		)}${langParam}${currencyParam}${unavailableTours}`;
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(receiveProducts({ productCards: json.tourGroups }));
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const fetchProduct =
	({
		id,
		lang = 'en',
		req,
		clientCurrencyCode,
		secondaryCity,
		isTranslationRequest = false,
		message,
	}: any) =>
	(dispatch: any, getState: any) => {
		const langParam = getApiLanguageParameter(lang);
		const currencyCode =
			getCurrentCurrency(getState()) || clientCurrencyCode;

		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const secondaryCityParam = secondaryCity
			? `secondary-city=${secondaryCity}`
			: '';

		const queryParams = [langParam, currencyParam, secondaryCityParam];

		const queryString = queryParams.filter(query => !!query).join('&');
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v6/tour-groups/${id}?${queryString}`;

		if (!id) {
			sendCoralogixLog({
				title: `fetchProduct called without id`,
				severity: LOG_LEVELS.WARNING as any,
				methodName: 'fetchProducts',
				metaData: message,
				category: LOG_CATEGORIES.API_ERROR,
			});
		}

		if (isTranslationRequest) {
			dispatch(requestTranslatedProductContent({ productId: id }));
		} else {
			dispatch(requestProduct({ id }));
		}

		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(productJson => {
				if (isTranslationRequest) {
					dispatch(
						receiveTranslatedProductContent({
							productJson,
							url,
						}),
					);
				} else {
					dispatch(
						receiveProduct({
							productJson,
							url,
						}),
					);
				}
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const fetchProductFeatureFlags =
	({ id }: { id: string | number }) =>
	(dispatch: Dispatch, getState: any) => {
		if (!id) {
			sendCoralogixLog({
				title: 'product id not found for fetching feature flags',
				severity: LOG_LEVELS.WARNING as any,
				methodName: 'fetchProductFeatureFlags',
				category: LOG_CATEGORIES.TGID_NOT_FOUND,
			});
			return;
		}
		const hostName = getApiCDNBaseUrl({ state: getState() });
		const featureFlagsAPIEndpoint = `${hostName}${ENDPOINTS.FEATURE_FLAGS}${id}`;

		return fetch(featureFlagsAPIEndpoint)
			.then(response => response.json())
			.then(featureFlags => {
				dispatch(receiveProductFeatureFlags({ id, featureFlags }));
			})
			.catch(err => {
				//no-op
				sendCoralogixLog({
					title: 'failed to fetch product feature flags',
					severity: LOG_LEVELS.ERROR as any,
					metaData: err,
					methodName: 'fetchProductFeatureFlags',
					category: LOG_CATEGORIES.API_ERROR,
				});
				return Promise.resolve();
			});
	};
