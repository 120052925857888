import { getLocalizedMetaTags } from 'Utils/localizationUtils';

import { fetchProductListByTag } from 'Thunks/productList';
import { changePage } from 'Actions/page';

import {
	COLLECTIONS_LIMIT,
	GLOBAL_CITY_CODE,
	GLOBAL_HEADOUT_PICKS_TAG,
	HEADOUT_PICKS_EXPERIENCES,
	PAGE_TYPE,
	SEO_INDEXABLE_LANGUAGE_CODES,
} from 'Constants/constants';

import { trackPageView } from './analytics';
import {
	getCitiesMap,
	getCurrentCityCode,
	getCurrentCountryCode,
	getCurrentLanguageCode,
	getDiscoverableCityCodes,
	getHost,
	getProductIdListFromTag,
	getUser,
} from './stateUtils';
import { getBaseUrl, getSEOLanguageLabel } from './urlUtils';

export const getMetaTags = () => {
	const tags = SEO_INDEXABLE_LANGUAGE_CODES.map(lang => {
		return {
			rel: 'alternate',
			hreflang: getSEOLanguageLabel(lang),
			href: `${getBaseUrl()}${
				lang.toLowerCase() !== 'en' ? `/${lang}/` : ``
			}`,
		};
	});
	tags.push({
		rel: 'alternate',
		hreflang: 'x-default',
		// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
		href: getBaseUrl(),
	});
	return tags;
};

// @ts-expect-error TS(7031): Binding element 'lang' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getCanonicalUrl = ({ params: { lang } }) => {
	const langPrefix = lang && lang.toUpperCase() !== 'EN' ? `/${lang}` : ``;
	return `${getBaseUrl()}${langPrefix}`;
};

export const checkIsHomePage = (currentPage: string) =>
	currentPage === PAGE_TYPE.HOME;
export const sendHomePageViewEvent = () => {
	trackPageView({
		pageType: PAGE_TYPE.HOME,
	});
};

export const getTitle = ({ lang }: any) =>
	getLocalizedMetaTags(lang, 'HOMEPAGE.TITLE');

export const getMetaDescription = ({ lang }: any) =>
	getLocalizedMetaTags(lang, 'HOMEPAGE.DESCRIPTION');

export const mapStateToProps = (state: any, ownProps: any) => ({
	cityCodes: getDiscoverableCityCodes(state),
	citiesMap: getCitiesMap(state),
	currentCityCode: getCurrentCityCode(state),
	user: getUser(state),
	host: getHost(state),
	headoutPicksProductIds: getProductIdListFromTag(
		state,
		GLOBAL_HEADOUT_PICKS_TAG,
	).slice(0, HEADOUT_PICKS_EXPERIENCES),
	paramLang: ownProps.params && ownProps.params.lang,
	countryCode: getCurrentCountryCode(state),
	languageCode: getCurrentLanguageCode(state),
});

export const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
	onMountDispatch() {
		dispatch(changePage(PAGE_TYPE.HOME));
		dispatch(
			fetchProductListByTag({
				cityCode: GLOBAL_CITY_CODE,
				params: `sort-type=${GLOBAL_HEADOUT_PICKS_TAG}`,
				lang: ownProps.params.lang,
				tag: GLOBAL_HEADOUT_PICKS_TAG,
				limit: COLLECTIONS_LIMIT.GLOBAL_HOMEPAGE,
			}),
		);
	},
});
